import * as React from "react";
import {graphql} from "react-relay";
import {useOutletContext} from "react-router";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import {commitMutation, useFragment, useRelayEnvironment} from "react-relay/hooks";
import Panel from "./presentational/Panel";
import EditableDataListItem from "./form/EditableDataListItem";
import EditableDataListField, {FieldDataType} from "./form/EditableDataListField";
import ActionButton from "./presentational/button/ActionButton";
import DeleteProjectDialog from "./dialog/DeleteProjectDialog";
import DataListItem from "./presentational/DataListItem";
import {languages} from "../Languages";
import Dinero from "dinero.js";
import Label from "./form/Label";
import Section from "./form/Section";
import Row from "./form/Row";
import {faBoxArchive, faTrash, faWavePulse} from "@fortawesome/pro-regular-svg-icons";
import ArchiveProjectDialog from "./dialog/ArchiveProjectDialog";
import ReactivateProjectDialog from "./dialog/ReactivateProjectDialog";

type Props = {}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type ProjectDetailsViewProps = Props & ConnectedProps<typeof connector>;

const handleRenameProjectSubmission = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation ProjectDetailsViewRenameProjectMutation($input: RenameProjectInput!) {
                    renameProject(input: $input) {
                        id
                        projectName
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const handleUpdateProjectPurchaseOrderReferenceSubmission = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation ProjectDetailsViewUpdateProjectPurchaseOrderReferenceMutation($input: UpdateProjectPurchaseOrderReferenceInput!) {
                    updateProjectPurchaseOrderReference(input: $input) {
                        id
                        purchaseOrderReference
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const handleUpdateProjectCustomerOrderNotesSubmission = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation ProjectDetailsViewUpdateProjectCustomerOrderNotesMutation($input: UpdateProjectCustomerOrderNotesInput!) {
                    updateProjectCustomerOrderNotes(input: $input) {
                        id
                        customerOrderNotes
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const handleUpdateProjectBillingSubmission = (input, setEditingActive, relayEnvironment) => {
    input.hourlyRate = input.hourlyRate === null ? null : Math.floor(input.hourlyRate)
    input.fixedFee = input.fixedFee === null ? null : Math.floor(input.fixedFee)

    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation ProjectDetailsViewUpdateProjectBillingMutation($input: UpdateProjectBillingInput!) {
                    updateProjectBilling(input: $input) {
                        id
                        billingMode
                        hourlyRate
                        fixedFee
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const ProjectDetailsView: React.FC<ProjectDetailsViewProps> = (props) => {
    const queryRef: any = useOutletContext();
    const project = useFragment(
        graphql`
            fragment ProjectDetailsView_project on Project {
                id
                projectName
                projectNumber
                state
                customerAccount {
                    id
                    customerName
                    customerNumber
                }
                language
                billingMode
                fixedFee
                hourlyRate
                hoursBudget
                costBudget
                purchaseOrderReference
                customerOrderNotes
                ...ArchiveProjectDialog_project
                ...ReactivateProjectDialog_project
                ...DeleteProjectDialog_project
            }
        `,
        queryRef
    );
    const relayEnvironment = useRelayEnvironment();
    const {dispatchShowModal, dispatchHideModal} = props;

    const showDeleteProjectDialog = () => {
        dispatchShowModal({
            body: (<DeleteProjectDialog queryRef={project} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    const showArchiveProjectDialog = () => {
        dispatchShowModal({
            body: (<ArchiveProjectDialog queryRef={project} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    const showReactivateProjectDialog = () => {
        dispatchShowModal({
            body: (<ReactivateProjectDialog queryRef={project} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    const formattedHourlyRate = Dinero({amount: project.hourlyRate ?? 0}).toFormat('$0.00')
    const formattedFixedFee = Dinero({amount: project.fixedFee ?? 0}).toFormat('$0.00')

    return (
        <>
            <Panel>
                <dl className="divide-y divide-gray-200">
                    <EditableDataListItem
                        label="Project Name"
                        fields={(<EditableDataListField fieldDataType={FieldDataType.text} name="projectName" autoFocus={true}/>)}
                        initialValues={{id: project.id, projectName: project.projectName}}
                        handleSubmission={(values, setEditingActive) => handleRenameProjectSubmission(values, setEditingActive, relayEnvironment)}
                    >
                        {project.projectName}
                    </EditableDataListItem>
                    <DataListItem label="Document language">{languages[project.language]}</DataListItem>
                </dl>
            </Panel>
            <Panel>
                <dl className="divide-y divide-gray-200">
                    <EditableDataListItem
                        label="Billing"
                        fields={
                            (
                            <Section>
                                <Row>
                                    <Label>Billing mode</Label>
                                    <EditableDataListField fieldDataType={FieldDataType.select} name="billingMode">
                                        <option value="NON_BILLABLE">Non-billable</option>
                                        <option value="TIME_AND_MATERIALS">Time and materials</option>
                                        <option value="FIXED_FEE">Fixed fee</option>
                                    </EditableDataListField>
                                </Row>
                                <Row>
                                    <Label>Hourly rate (in Euro Cents 🙊)</Label>
                                    <EditableDataListField name="hourlyRate" fieldDataType={FieldDataType.text}/>
                                </Row>
                                <Row>
                                    <Label>Fixed fee (in Euro Cents 🙈)</Label>
                                    <EditableDataListField name="fixedFee" fieldDataType={FieldDataType.text}/>
                                </Row>
                            </Section>
                        )}
                        initialValues={{
                            id: project.id,
                            billingMode: project.billingMode,
                            hourlyRate: project.hourlyRate === undefined ? 0 : project.hourlyRate,
                            fixedFee: project.fixedFee === undefined ? 0 : project.fixedFee,
                        }}
                        handleSubmission={(values, setEditingActive) => handleUpdateProjectBillingSubmission(values, setEditingActive, relayEnvironment)}
                    >
                        {project.billingMode === 'NON_BILLABLE' && (<>{'Non-billable'}</>)}
                        {project.billingMode === 'TIME_AND_MATERIALS' && (<>{'Time and materials, charging ' + formattedHourlyRate + ' per hour'}</>)}
                        {project.billingMode === 'FIXED_FEE' && (<>{'Fixed fee, charging ' + formattedFixedFee + ' in total'}</>)}
                    </EditableDataListItem>
                </dl>
            </Panel>
            <Panel>
                <dl className="divide-y divide-gray-200">
                    <EditableDataListItem
                        label="Purchase order reference"
                        fields={(<EditableDataListField fieldDataType={FieldDataType.text} name="purchaseOrderReference" autoFocus={true}/>)}
                        initialValues={{id: project.id, purchaseOrderReference: project.purchaseOrderReference ?? ''}}
                        handleSubmission={(values, setEditingActive) => handleUpdateProjectPurchaseOrderReferenceSubmission(values, setEditingActive, relayEnvironment)}
                    >
                        {project.purchaseOrderReference}
                    </EditableDataListItem>
                    <EditableDataListItem
                        label="Customer order notes"
                        fields={(<EditableDataListField fieldDataType={FieldDataType.text} name="customerOrderNotes" autoFocus={true}/>)}
                        initialValues={{id: project.id, customerOrderNotes: project.customerOrderNotes ?? ''}}
                        handleSubmission={(values, setEditingActive) => handleUpdateProjectCustomerOrderNotesSubmission(values, setEditingActive, relayEnvironment)}
                    >
                        {project.customerOrderNotes}
                    </EditableDataListItem>
                </dl>
            </Panel>
            <div className="flex space-x-4 justify-end">
                {project.state === 'ACTIVE' && (<ActionButton onClick={showDeleteProjectDialog} icon={faTrash} label="Delete Project"/>)}
                {project.state === 'ACTIVE' && (<ActionButton onClick={showArchiveProjectDialog} icon={faBoxArchive} label="Archive Project"/>)}
                {project.state === 'ARCHIVED' && (<ActionButton onClick={showReactivateProjectDialog} icon={faWavePulse} label="Reactivate Project"/>)}
            </div>
        </>
    )
}

export default connector(ProjectDetailsView);
